<template>
  <b-card-body>
    <div class="mt-0 mb-2 text-center">
      <h4>Related Tags</h4>
      <!-- <b-card-text>People also search for this items</b-card-text> -->
    </div>

    <!-- Swiper -->
    <swiper class="swiper-responsive-breakpoints px-4 py-2" :options="swiperOptions">
      <swiper-slide v-for="(tags, index) in relatedTags" :key="index">
        <b-row class="mb-1">
          <b-form-checkbox v-model="video.tagsKey" :value="tags.key" class="custom-control-primary">
            Default
          </b-form-checkbox>
        </b-row>
        <b-link :to="{ name: 'video', params: { id: video.key }, query: { key: tags.key } }">
          <div class="item-heading">
            <h5 class="text-truncate mb-0">
              {{ tags.user ? tags.user.name : 'no user' }}
            </h5>
            <small class="text-body">{{ tags.t_u | moment('calendar') }}</small>
          </div>
          <div class="img-container w-50 mx-auto py-75">
            <b-img style="width: 140px; height: 140px" :src="tags.user ? tags.user.picture : ''" rounded="circle" />
          </div>
          <div class="item-meta">
            <!-- <ul class="unstyled-list list-inline mb-25">
              <li :key="star" class="ratings-list-item">100 tags</li>
            </ul> -->
            <p class="card-text text-primary mb-0">{{ tags.tc || 'unknown' }} tags</p>
          </div>
        </b-link>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card-body>
</template>

<script>
import {
  BCardBody,
  // BCardText,
  BFormCheckbox,
  BImg,
  BRow,
  BLink,
} from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    BCardBody,
    // BCardText,
    BImg,
    BRow,
    BLink,
    BFormCheckbox,

    // 3rd Party
    Swiper,
    SwiperSlide,
  },
  props: {
    video: {
      type: Object,
      required: true,
      default: () => {},
    },
    relatedTags: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selected: '1',
      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 55,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1600: {
            slidesPerView: 4,
            spaceBetween: 55,
          },
          1300: {
            slidesPerView: 3,
            spaceBetween: 55,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 55,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 55,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
</style>
