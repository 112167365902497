<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <!-- <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'apps-e-commerce-shop' }">Shop</b-link>
        for other items.
      </div>
    </b-alert> -->

    <!-- Content -->
    <b-card no-body>
      <b-card-body v-if="video.id">
        <b-row class="my-2">
          <!-- Left: Product Image Container -->
          <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
            <div class="d-flex align-items-center justify-content-center">
              <b-img :src="video.coverUrl" alt="Photo" class="product-img" fluid />
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4>{{ video.snippet.title }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>by</span>
              <b-link class="company-name">
                {{ video.snippet.channelTitle }}
              </b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <h4 class="item-price mr-1">{{ video.viewCount }} views</h4>
              <!-- <ul class="unstyled-list list-inline pl-1 border-left">
                <li v-for="star in 5" :key="star" class="ratings-list-item mr-25">
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= product.rating },
                      star <= product.rating ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul> -->
            </div>

            <!-- Avability -->
            <b-card-text>
              Duration -
              <span class="text-success">{{ video.contentDetails.duration | duration('humanize') }}</span>
            </b-card-text>

            <!-- Product Description -->
            <b-card-text>{{ video.snippet.description }}</b-card-text>

            <!-- Product Meta [Free shpping, EMI, etc.] -->
            <!-- <ul class="product-features list-unstyled">
              <li v-if="true">
                <feather-icon icon="ShoppingCartIcon" />
                <span>Free Shipping</span>
              </li>
              <li>
                <feather-icon icon="DollarSignIcon" />
                <span>EMI options available</span>
              </li>
            </ul>-->

            <hr />

            <!-- Colors -->
            <!-- <div class="product-color-options">
              <h6>Colors</h6>
              <ul class="list-unstyled mb-0">
                <li
                  v-for="color in product.colorOptions"
                  :key="color"
                  class="d-inline-block"
                  :class="{ selected: selectedColor === color }"
                  @click="selectedColor = color"
                >
                  <div class="color-option" :class="`b-${color}`">
                    <div class="filloption" :class="`bg-${color}`" />
                  </div>
                </li>
              </ul>
            </div> -->

            <!-- <hr /> -->

            <div class="d-flex flex-column flex-sm-row pt-1">
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click.prevent="saveVideo"
              >
                <feather-icon icon="SaveIcon" class="mr-50" @click="saveVideo" />
                <span>Save Video</span>
              </b-button> -->

              <b-button
                v-if="savedVideo"
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="toggleFavorite"
              >
                <feather-icon icon="HeartIcon" class="mr-50" :class="{ 'text-danger': isFavorite }" />
                <span>Favorite</span>
              </b-button>

              <v-select
                v-if="savedVideo"
                v-model="category"
                class="w-50"
                dir="ltr"
                multiple
                label="title"
                :options="categoryOption"
                @input="changeCategory"
              />

              <!-- <b-dropdown variant="outline-secondary" no-caret toggle-class="btn-icon" class="btn-share" right>
                <template #button-content>
                  <feather-icon icon="Share2Icon" />
                </template>
                <b-dropdown-item
                  v-for="icon in ['FacebookIcon', 'TwitterIcon', 'YoutubeIcon', 'InstagramIcon']"
                  :key="icon"
                >
                  <feather-icon :icon="icon" />
                </b-dropdown-item>
              </b-dropdown> -->
            </div>
          </b-col>
        </b-row>
      </b-card-body>

      <!-- Static Content -->
      <!-- <e-commerce-product-details-item-features /> -->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <you-tube-video-details-related-tags v-if="savedVideo" :video="savedVideo" :related-tags="relatedTags" />
    </b-card>
  </section>
</template>

<script>
// import { useRouter } from '@core/utils/utils'
import store from '@/store'
// import { ref } from '@vue/composition-api'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BButton,
  // BDropdown,
  // BDropdownItem,
  // BAlert,
  // BFormFile,
  // BForm,
  // BFormGroup,
  // BFormTextarea,
  // BInputGroup,
  // BFormInput,
  // BInputGroupAppend,
  // BCollapse,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'

import mixinUI from '@/store/ui/mixin'
import mixinServer from '@/store/server/mixin'
import mixinDebug from './mixinDebug'

import YouTubeVideoDetailsRelatedTags from './parts/YouTubeVideoDetailsRelatedTags.vue'

export default {
  name: 'ViewGalleryPhoto',
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    // ValidationProvider,
    // ValidationObserver,
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    // BDropdown,
    // BDropdownItem,
    // BAlert,
    // BFormFile,
    // BForm,
    // BFormGroup,
    // BFormTextarea,
    // BInputGroup,
    // BFormInput,
    // BInputGroupAppend,
    vSelect,
    // BCollapse,

    // SFC
    YouTubeVideoDetailsRelatedTags,
  },
  mixins: [mixinUI, mixinServer, mixinDebug],
  props: {
    videoKey: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      video: {},
      savedVideo: null,
      isFavorite: false,
      category: [],
      categoryOption: [
        { title: 'World' },
        { title: 'Asia' },
        { title: 'Africa' },
        { title: 'North America' },
        { title: 'South America' },
        { title: 'Europe' },
        { title: 'Australia' },
      ],
      relatedTags: [],
    }
  },
  computed: {
    photoKeywordsOption() {
      return this.userPhotoKeywords.split(',').map(word => ({ title: word }))
    },
    myUserPhotoKeywords: {
      get() {
        return this.userPhotoKeywords
      },
      set(val) {
        this.tmpUserPhotoKeywords = val
      },
    },
    videoId() {
      return this.$route.params.videoId || this.videoKey
    },
    tagsKey() {
      return this.savedVideo ? this.savedVideo.tagsKey : false
    },
  },
  watch: {
    videoId() {
      this.log('videoId changed', this.videoId)
      this.updateVideoId()
    },
    tagsKey(newVal, oldVal) {
      this.log(newVal, oldVal)
      if (this.tagsKey && oldVal !== false) {
        store
          .dispatch('server/editVideo', {
            key: this.videoId,
            tagsKey: this.tagsKey,
          })
          .then(r => {
            this.log(r)
            this.toastS('saved successfully')
          })
          .catch(this.toastE)
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch('server/fetchAllVideos')
      .then(() => {
        next()
      })
      .catch(() => {
        this.log('fetchAllVideos error')
        next()
      })
  },
  beforeMount() {
    this.updateVideoId()
  },
  methods: {
    updateVideoId() {
      store
        .dispatch('server/fetchVideo', { videoId: this.videoId })
        .then(video => {
          this.log(video)
          this.video = video

          this.getSavedVideo()

          store
            .dispatch('server/getTagsOfVideo', this.videoId)
            .then(r => {
              this.log(r.rows)
              this.relatedTags = r.rows
            })
            .catch(e => {
              this.log(e)
            })
        })
        .catch(e => {
          this.log(e)
          this.$router.push({ name: 'error-404' })
        })
    },
    getSavedVideo() {
      store
        .dispatch('server/getVideo', this.videoId)
        .then(savedVideo => {
          this.log(savedVideo)
          this.savedVideo = savedVideo
          this.isFavorite = savedVideo.favorite
          this.category = (savedVideo.category || []).map(title => ({
            title,
          }))
        })
        .catch(e => {
          this.log(e)
          this.savedVideo = null
        })
    },
    // saveVideo() {
    //   const data = {
    //     key: this.videoId,
    //     data: this.video,
    //   }
    //   store
    //     .dispatch('server/saveVideo', data)
    //     .then(r => {
    //       this.log(r)
    //       this.toastS('saved successfully')

    //       if (!this.savedVideo) {
    //         this.getSavedVideo()
    //       }
    //     })
    //     .catch(this.toastE)
    // },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite
      store
        .dispatch('server/editVideo', {
          key: this.videoId,
          favorite: this.isFavorite ? 1 : 0,
        })
        .then(r => {
          this.log(r)
          this.toastS('saved successfully')
        })
        .catch(this.toastE)
    },
    changeCategory(val) {
      const category = val.map(v => v.title)
      store
        .dispatch('server/editVideo', {
          key: this.videoId,
          category,
        })
        .then(r => {
          this.log(r)
          this.toastS('saved successfully')
        })
        .catch(this.toastE)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
</style>

<style lang="scss" scoped>
.ecommerce-application .app-ecommerce-details .product-img {
  width: 100% !important;
}
</style>
